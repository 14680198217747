import { WebChatInstance } from "@ibm-watson/assistant-web-chat-react";

import { ChatwootClient } from "./client";

import { Status } from "../types";
import { MessageRequest, ServiceDesk, ServiceDeskFactoryParameters } from "../types/serviceDesk";

export class ChatwootServiceDeskFactory implements ServiceDesk {
  chatwoot: ChatwootClient;
  instance: WebChatInstance;

  constructor(
    chatwoot: ChatwootClient,
    { callback, instance }: ServiceDeskFactoryParameters
  ) {
    this.instance = instance as WebChatInstance;
    this.chatwoot = chatwoot;
    this.chatwoot.setCallback(callback);
  }

  getName = () => "chatwoot";

  startChat = async () => {
    await this.chatwoot.setStatus(Status.Open);
  };

  endChat = async () => {
    const { isConnectingWithHumanAgent } = this.instance.getState();
    if (isConnectingWithHumanAgent) {
      return;
    }

    // When a chat is ended it is marked as resolved and a new conversation is started
    await this.chatwoot.setStatus(Status.Resolved);
    await this.chatwoot.restartConversation();

    // Wait one second before ending the chat to allow CSAT to be shown to user
    await new Promise((r) => setTimeout(r, 1000));
  };

  sendMessageToAgent = async (message: MessageRequest) => {
    await this.chatwoot.sendMessage(message.input.text);
  };

  areAnyAgentsOnline = async () => {
    const agentCount = await this.chatwoot.getAvailableAgentCount();

    // Open the conversation if there are no agents online
    if (agentCount === 0) {
      await this.chatwoot.setStatus(Status.Open);
    }

    return agentCount > 0;
  };
}
