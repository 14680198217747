import { z } from "zod";

export enum ContentType {
  Survey = "survey",
  Carousel = "carousel",
}

export enum Status {
  Pending = "pending",
  Open = "open",
  Resolved = "resolved",
}

export enum MessageType {
  User = "human",
  Bot = "ai",
  Agent = "agent"
}

export enum Relevancy {
  Relevant = 10,
  Irrelevant = 0,
}
export type LinkCard = z.infer<typeof linkCardSchema>;

export const linkCardSchema = z.strictObject({
  title: z.string(),
  text: z.string(),
  url: z.string().url(),
});

export const relevancySchema = z.nativeEnum(Relevancy);
