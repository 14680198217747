import { WebChatConfig, WebChatContainer, WebChatInstance } from "@ibm-watson/assistant-web-chat-react";
import { UserDefinedResponseEvent } from "@ibm-watson/assistant-web-chat-react/dist/types/UserDefinedResponseEvent";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { ContentCarousel } from "./ContentCarousel";
import { CustomerSurvey } from "./CustomerSurvey";
import { IsolatedComponent } from "./IsolatedComponent";

import { ChatwootClient } from "../chatwoot/client";
import { ChatwootServiceDeskFactory } from "../chatwoot/serviceDesk";
import { useWatsonHistory } from "../hooks/history";
import {
  ContentType,
  ConversationHistory,
  Message,
  recieveDataSchema,
  RecieveEvent,
  recieveResponseSchema,
  RecieveTextResponse,
  recieveUserDefinedResponseSchema,
  SendEvent,
} from "../types";
import { ServiceDeskFactoryParameters } from "../types/serviceDesk";
import { findLastUserMessage } from "../utils/analytics";
import { IFrameHelper } from "../utils/iframe";

interface ChatInterfaceProps {
  integrationID: WebChatConfig["integrationID"];
  region: WebChatConfig["region"];
  serviceInstanceID: WebChatConfig["serviceInstanceID"];
  namespace?: string;
  chatwootBaseUrl: string;
  chatwootWebsocketUrl: string;
  chatwootInboxId: string;
}

const renderUserDefinedResponse = (
  conversationHistory: ConversationHistory,
  chatwootClient: ChatwootClient
) => (event: UserDefinedResponseEvent) => {
  const responseMessage = recieveResponseSchema.parse(event.data.message);
  const responseData = recieveDataSchema.parse(event.data.fullMessage);

  const response = recieveUserDefinedResponseSchema.safeParse(responseMessage);
  if (!response.success) {
    return null;
  }

  const { user_defined: content } = response.data;
  switch (content.type) {
    case ContentType.Survey:
      return (
        <IsolatedComponent>
          <CustomerSurvey
            surveyId={content?.surveyId}
            client={chatwootClient}
            sx={{
              borderRadius: "var(--cds-chat-BASE-border-radius-med)",
              border: "1px solid var(--cds-chat-bubble-border,#e0e0e0)",
            }}
          />
        </IsolatedComponent>
      );
    case ContentType.Carousel: {
      const textOutput = responseData.output.generic.find(
        (output) => output.response_type === "text"
      ) as RecieveTextResponse;

      const lastUserMessage = textOutput && findLastUserMessage(conversationHistory.messages, textOutput.text)?.text;

      return (
        <IsolatedComponent>
          <ContentCarousel
            cards={content.cards}
            conversationHistory={conversationHistory}
            userQuestion={lastUserMessage}
            query={content.searchQuery}
          />
        </IsolatedComponent>
      );
    }
    default:
      return null;
  }
};

export const ChatInterface = ({
  namespace,
  integrationID,
  region,
  serviceInstanceID,
  chatwootBaseUrl,
  chatwootWebsocketUrl,
  chatwootInboxId,
}: ChatInterfaceProps) => {
  const chatRef = useRef<HTMLElement>();
  const conversationRef = useRef<Message[]>([]);
  const [chatwoot] = useState(() => new ChatwootClient(chatwootBaseUrl, chatwootWebsocketUrl, chatwootInboxId));
  const [webChatConfig, setWebChatConfig] = useState<WebChatConfig>();

  const {
    onReceive,
    onSend,
    onHistoryEnd,
    onRestartConversation,
    conversationContext,
    conversationHistory,
  } = useWatsonHistory();

  useEffect(() => {
    conversationRef.current = conversationHistory;
  }, [conversationHistory]);

  useEffect(() => {
    if (!chatRef.current) {
      return;
    }

    setWebChatConfig({
      openChatByDefault: true,
      showLauncher: false,
      disableCustomElementMobileEnhancements: true,
      headerConfig: {
        minimizeButtonIconType: "close",
        hideMinimizeButton: !IFrameHelper.isIFrame(),
        showRestartButton: true,
      },
      themeConfig: {
        corners: "square",
        carbonTheme: "g10",
      },
      layout: {
        showFrame: false,
        hasContentMaxWidth: false,
      },
      integrationID,
      region,
      serviceInstanceID,
      namespace,
      element: chatRef.current,
      serviceDeskFactory: (params: ServiceDeskFactoryParameters) => new ChatwootServiceDeskFactory(chatwoot, params),
      servers: {
        assistantURLPrefix: `/watson/${region}/assistant`,
        webChatScriptPrefix: `/watson/${region}/webchat`,
      },
      clientVersion: "8",
    });
  }, [
    integrationID,
    region,
    serviceInstanceID,
    namespace,
    chatwoot,
    conversationRef,
  ]);

  const onAfterRender = async (instance: WebChatInstance) => {
    instance.on({ type: "history:end", handler: onHistoryEnd });

    instance.on({
      type: "receive", handler: async (event: RecieveEvent) => {
        console.debug("[instance] receive", event);
        await onReceive(event);
        await chatwoot.onReceivePatchUser(event);
        await chatwoot.onRecieve(event);
      },
    });

    instance.on({
      type: "pre:send", handler: async (event: SendEvent) => {
        console.debug("[instance] pre:send", event);
        const createdUser = await chatwoot.createContactIfNotExists();
        await chatwoot.createConversationIfNotExists();

        if (!createdUser || !event.data.context?.skills?.["actions skill"]?.skill_variables) {
          return;
        }

        console.log("[instance] Clearing user variables...");
        event.data.context.skills["actions skill"].skill_variables.name = null;
        event.data.context.skills["actions skill"].skill_variables.email = null;
      },
    });

    instance.on({
      type: "send", handler: async (event: SendEvent) => {
        if (event.data.history.is_welcome_request) {
          return;
        }

        console.debug("[instance] send", event);
        await onSend(event);

        await chatwoot.onSend(event);
        await chatwoot.updatePresence();
      },
    });

    instance.on({
      type: "restartConversation", handler: async () => {
        console.debug("[instance] restartConversation");
        await onRestartConversation();
        await chatwoot.restartConversation();
      },
    });

    instance.on({
      type: "view:change", handler: ({ reason }: any) => {
        if (IFrameHelper.isIFrame() && reason === "mainWindowMinimized") {
          IFrameHelper.sendMessage({ event: "closeWindow" });
        }
      },
    });

    window.addEventListener("message", (event) => {
      if (!IFrameHelper.isValidEvent(event)) {
        return;
      }

      const data = IFrameHelper.getMessage(event);
      if (data.event === "toggle-open" && data.isOpen) {
        instance.changeView("mainWindow");
      }
    });

    instance.restartConversation();
  };

  const history: ConversationHistory = { context: conversationContext, messages: conversationHistory };

  return (
    <>
      <Box ref={chatRef} height={1} width={1} />
      {webChatConfig && (
        <WebChatContainer
          config={webChatConfig}
          renderUserDefinedResponse={renderUserDefinedResponse(history, chatwoot)}
          onAfterRender={onAfterRender}
        />
      )}
    </>
  );
};
