import { faker } from "@faker-js/faker";
import { createConsumer } from "@rails/actioncable";
import { Cable, Channel } from "actioncable";
import axios, { AxiosInstance } from "axios";
import { v4 } from "uuid";

import { ContentType, ReceiveEvent, SendEvent, Status } from "../types";
import { ServiceDeskFactoryParameters } from "../types/serviceDesk";

export class ChatwootClient {
  consumer: Cable;
  privateClient: AxiosInstance;
  publicClient: AxiosInstance;
  publicInboxClient: AxiosInstance;

  callback: ServiceDeskFactoryParameters["callback"] | undefined;

  user: {
    id: number;
    source_id: string;
    name: string;
    email: string;
    pubsub_token: string;
  } | undefined;

  presenceInterval: ReturnType<typeof setInterval> | undefined;

  subscription: Channel | undefined;
  activeConversationId: string | undefined;
  agentId: string | number | undefined;

  constructor(
    baseUrl: string,
    websocketUrl: string,
    inboxId: string
  ) {
    this.consumer = createConsumer(`${websocketUrl}/cable`);

    this.privateClient = axios.create({
      baseURL: `/chatwoot`,
    });

    this.publicClient = axios.create({
      baseURL: `${baseUrl}/public/api/v1`,
    });

    this.publicInboxClient = axios.create({
      baseURL: `${baseUrl}/public/api/v1/inboxes/${inboxId}`,
    });
  }

  genUserName = () => `Anon-${faker.word.noun({ length: { min: 5, max: 8 } })}-${faker.number.int({ min: 100, max: 1000 })}`;

  createContact = async (userId: string) => {
    const { data } = await this.publicInboxClient.post(`/contacts`, { identifier: userId, name: this.genUserName() });
    this.user = data;
    return data;
  };

  getContact = async () => {
    const { data } = await this.publicInboxClient.get(`/contacts/${this.user?.source_id}`);
    console.log("USER", data);
    return data;
  };

  patchContact = async (user: { email?: string; name?: string }) => {
    await this.publicInboxClient.patch(`/contacts/${this.user?.source_id}`, user);
    this.user = await this.getContact();
  };

  // TODO: Will need to resubscribe to the channel after the contact is updated
  // currently this does not work as expected when a contact is merged as the old user_id key
  // still exists in redis, which is used for presence tracking (that or the channel does not have the correct pubsub_token)
  updatePresence = async () => {
    console.debug("[Chatwoot] Updating presence...");
    await this.consumer.send({
      command: "message",
      identifier: JSON.stringify({ channel: "RoomChannel", pubsub_token: this.user?.pubsub_token }),
      data: JSON.stringify({ action: "update_presence" }),
    });
  };

  /**
   * Creates a new user if one does not exist
   * @returns true if a new user was created
   */
  createContactIfNotExists = async (): Promise<boolean> => {
    if (this.user) {
      return false;
    }

    const user = await this.createContact(v4());
    console.debug("[Chatwoot] Creating user...", { sourceId: user.source_id });

    this.subscription = this.consumer.subscriptions.create({
      channel: "RoomChannel",
      pubsub_token: this.user!.pubsub_token,
    }, {
      received: this.onMessage,
    });
    this.presenceInterval = setInterval(() => this.updatePresence(), 20000);
    return true;
  };

  /**
   * Creates a new conversation if one does not exist
   * @returns true if a new conversation was created
   */
  createConversationIfNotExists = async (): Promise<boolean> => {
    if (this.activeConversationId) {
      return false;
    }

    const { data } = await this.privateClient.post(`/conversations`, {
      source_id: this.user?.source_id,
    });
    console.debug("[Chatwoot] Creating conversation...", { conversationId: data.id });

    this.activeConversationId = data.id;
    return true;
  };

  restartConversation = async () => {
    console.debug("[Chatwoot] Restarting conversation...");
    this.agentId = undefined;
    this.activeConversationId = undefined;
    this.user = undefined;

    this.presenceInterval && clearInterval(this.presenceInterval);
    this.presenceInterval = undefined;

    this.subscription?.unsubscribe();
    this.subscription = undefined;
  };

  setCallback = (callback: ServiceDeskFactoryParameters["callback"]) => {
    this.callback = callback;
  };

  getVariableValue = (variable?: any): string | undefined => typeof variable === "object" ? variable.value : variable;
  formatName = (name: string | undefined) => name?.replace(/\s\s+/g, " ").split(" ").map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join(" ");

  onRecieve = async (event: ReceiveEvent) => {
    if (!this.activeConversationId) {
      return;
    }

    const [message] = event.data.output.generic;

    if (message.response_type === "text") {
      await this.privateClient?.post(`/messages`, { content: message.text, conversation_id: this.activeConversationId });
    }
  };

  onReceivePatchUser = async (event: ReceiveEvent) => {
    if (!this.user) {
      return;
    }

    const { email, name } = event.data.context?.skills?.["actions skill"]?.skill_variables || {};
    const _email = email && this.getVariableValue(email);
    const _name = name && this.formatName(this.getVariableValue(name));

    if (!_email && !_name) {
      return;
    }

    console.log("[Chatwoot] Patching user...", { email: _email, name: _name });
    await this.patchContact({
      ...(_email && { email: _email }),
      ...(_name && { name: _name }),
    });
  };

  onSend = async (event: SendEvent) => {
    await this.sendMessage(event.data.input.text);
  };

  sendMessage = async (content: string) => this.publicInboxClient?.post(`/contacts/${this.user?.source_id}/conversations/${this.activeConversationId}/messages`, { content });
  setStatus = async (status: Status) => this.privateClient?.post("/toggle_status", { conversation_id: this.activeConversationId, status });

  onMessage = (message: any) => {
    const { data, event } = message;
    event && console.debug("[Chatwoot] onMessage", { event, data });

    switch (event) {
      case "conversation.updated":
        return this.onConversationUpdated(data);
      case "message.created":
        return this.onMessageCreated(data);
      case "conversation.status_changed":
        return this.onStatusChanged(data);
      case "conversation.typing_on":
        return this.onTyping(data)(true);
      case "conversation.typing_off":
        return this.onTyping(data)(false);
    }
  };

  onConversationUpdated = ({ meta, status, id }: any) => {
    const { assignee } = meta;

    if (this.activeConversationId !== id || status !== Status.Open) {
      return;
    }

    if (!assignee || !assignee.id || this.agentId === assignee.id) {
      return;
    }

    this.agentId = assignee.id;
    this.callback?.agentJoined({ id: this.agentId! as string, nickname: assignee.name });
  };

  getSurveyId = (message: string) => {
    const regex = /https?:\/\/[^\s]+\/survey\/responses\/([^\s]+)/;
    const [, match] = message.match(regex) || [];
    return match;
  };

  onMessageCreated = async ({ content, message_type, sender_type, conversation_id }: any) => {
    if (sender_type === "AgentBot") {
      return;
    }

    // Handle CSAT survey message
    const surveyId = this.getSurveyId(content);
    if (surveyId) {
      await this.callback?.sendMessageToUser({
        id: [...crypto.getRandomValues(new Uint8Array(16))].map(b => b.toString(16)).join(""),
        output: {
          generic: [{
            response_type: "user_defined",
            user_defined: {
              type: ContentType.Survey,
              surveyId: surveyId,
            },
          }],
        },
      });
      await this.callback?.agentTyping(false);
      return;
    }

    // Handle regular messages
    if (message_type === 0 || conversation_id !== this.activeConversationId) {
      return;
    }

    await this.callback?.sendMessageToUser(content);
    await this.callback?.agentTyping(false);
  };

  onStatusChanged = async ({ status, performer, id }: any) => {
    if (this.activeConversationId !== id) {
      return;
    }

    if (status === "resolved" && performer && performer.type !== "agent_bot") {
      await this.callback?.agentEndedChat();
    }
  };

  onTyping = ({ conversation }: any) => (setTyping: boolean) => {
    if (this.activeConversationId !== conversation.id) {
      return;
    }

    this.callback?.agentTyping(setTyping);
  };

  putCustomerSurvey = async (surveyId: string, rating?: number, feedback?: string) => {
    await this.publicClient.put(`/csat_survey/${surveyId}`, {
      message: {
        submitted_values: {
          csat_survey_response: {
            rating: rating || 0,
            feedback_message: feedback || "",
          },
        },
      },
    });
  };

  getCustomerSurvey = async (surveyId: string) => {
    const { data } = await this.publicClient.get(`/csat_survey/${surveyId}`);
    return data?.csat_survey_response;
  };

  getAvailableAgentCount = async () => {
    const { data } = await this.privateClient.get(`/availablity`);
    return data.availableAgents;
  };
}
