import {
  Button,
  Card,
  CardActions,
  CardContent,
  Rating,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ChatwootClient } from "../chatwoot/client";

interface CustomerSurveyProps {
  sx?: SxProps;
  surveyId: string;
  client: ChatwootClient;
}

export const CustomerSurvey = ({ sx, surveyId, client }: CustomerSurveyProps) => {
  const theme = useTheme();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [feedback, setFeedback] = useState<string>("");

  useEffect(() => {
    (async () => {
      const results = await client.getCustomerSurvey(surveyId);

      if (!results) {
        return;
      }

      setSubmitted(true);
      setRating(results.rating);
      setFeedback(results.feedback_message);
    })();
  }, [client, surveyId]);

  const onSubmit = () => {
    client.putCustomerSurvey(surveyId, rating, feedback);
    setSubmitted(true);
  };

  return (
    <Card elevation={0} sx={sx}>
      <CardContent sx={{ pb: 0 }}>
        <Stack gap={1}>
          {submitted
            ? <Typography>Thank you for submitting your feedback!</Typography>
            : <Typography>Let us know how we did</Typography>
          }
          <Rating
            disabled={submitted}
            value={rating}
            onChange={(_, newValue) => {
              if (!newValue) {
                return;
              }

              setRating(newValue);
              client.putCustomerSurvey(surveyId, newValue);
            }}
            sx={{
              "& .MuiRating-iconHover": {
                color: theme.palette.info.main,
              },
            }}
          />
          {!!rating && !submitted && <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderColor: "var(--cds-border-subtle)",
                "&:hover fieldset": {
                  borderColor: "var(--cds-border-subtle)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "var(--cds-focus)",
                },
              },
            }}
            minRows={3}
            multiline
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
            placeholder="How can we improve?"
            fullWidth />}
        </Stack>
      </CardContent>
      <CardActions>
        {!!rating && !submitted && <Button
          sx={{
            color: "var(--cds-chat-PRIMARY-color, #0050e6)",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }}
          onClick={() => onSubmit()}>
          Submit
        </Button>}
      </CardActions>
    </Card>
  );
};
