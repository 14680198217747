import { useCallback, useState } from "react";

import { Context, Message, MessageType, ReceiveEvent, SendEvent } from "../types";

export const useWatsonHistory = () => {
  const [conversationHistory, setConversationHistory] = useState<Message[]>([]);
  const [conversationContext, setConversationContext] = useState<Context>();

  const onReceiveFromAssistant = useCallback((event: ReceiveEvent) => {
    const {
      data: {
        output: { generic },
        context,
      },
    } = event;

    if (!generic?.length) {
      return;
    }

    const timestamp = new Date().getTime();

    const messages = generic.map((generic, index) => ({
      ts: timestamp + index,
      type: "agent_message_type" in generic && generic.agent_message_type ? MessageType.Agent : MessageType.Bot,
      ...generic,
    } as Message));

    setConversationHistory((prev) => [...prev, ...messages]);
    setConversationContext(context);
  }, []);

  const onSendToAssistant = useCallback((event: SendEvent) => {
    const {
      data: {
        input: { text, message_type: messageType },
        context,
      },
    } = event;

    if (!text) {
      return;
    }

    const timestamp = new Date().getTime();

    const message = {
      ts: timestamp,
      type: MessageType.User,
      message_type: messageType,
      text,
    } as Message;

    setConversationHistory((prev) => [...prev, message]);
    setConversationContext(context);
  }, []);

  const onHistoryEnd = useCallback((event: any) => {
    const { messages = [] } = event;

    messages.forEach((message: any) => {
      if ("input" in message) {
        return onSendToAssistant({ data: message, type: "send" });
      }
      if ("output" in message) {
        return onReceiveFromAssistant({ data: message, type: "receive" });
      }
    });
  }, [onSendToAssistant, onReceiveFromAssistant]);

  const onRestartConversation = useCallback(() => {
    setConversationHistory([]);
    setConversationContext(undefined);
  }, []);

  return {
    onSend: onSendToAssistant,
    onReceive: onReceiveFromAssistant,
    onHistoryEnd,
    onRestartConversation,
    conversationHistory,
    conversationContext,
  };
};
